import React from "react"
import Layout from "./layout"
import { Link, ModalRoutingContext } from 'gatsby-plugin-modal-routing'
import './Modal-Layout.css';
// const styling={
//   width:600,
//   height:600,
//   position:'absolute',
//   left: '50%',
//   top: '50%',
//   transform: 'translate(-50%, -50%)',
//   borderRadius: '25px',
// }

const ConditionalLayout = ({ children, ...rest }) => (
  <ModalRoutingContext.Consumer>
    {({ modal, closeTo }) => (
      modal ? (
        <React.Fragment>
          <Link to={closeTo}>
            X
          </Link>
          {children}
        </React.Fragment>
      ) : (
        <Layout { ...rest }>
          {children}
        </Layout>
      )
    )}
  </ModalRoutingContext.Consumer>
)

export default ConditionalLayout
