import React from 'react'

class Logo extends React.Component{
  constructor(props) {
    super(props);
    this.state = {
      width: 0, // or your default width here
      height:0,
    }
  }

  componentDidMount() {
    this.handleWindowSizeChange() // Set width
    window.addEventListener('resize', this.handleWindowSizeChange)
  }

  handleWindowSizeChange = () => {
    this.setState({ 
      width: window.innerWidth,
      height: window.innerHeight
    })
  }
  
  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowSizeChange)
  }

  render(){
    var divStyle = {
      height:50
    }
    var imgRes = require("../../static/logos/logo.png");

    if(this.state.width>576){
      divStyle = {
        height:80
      }
    }

    if(this.state.width>768){
      divStyle = {
        height:60
      }
      imgRes = require("../../static/logos/mobius-logo.png");
    }
    if(this.state.width>992){
      divStyle = {
        height:60
      }
      imgRes = require("../../static/logos/mobius-logo.png");
    }

    return(
      <img style={divStyle} src={imgRes}></img>
    );
  }
}

export default Logo