import React from "react"
import { Link } from 'gatsby-plugin-modal-routing'

import { MDBContainer, MDBRow, MDBCol, MDBBtn, MDBIcon, MDBInput } from 'mdbreact'
import addToMailchimp from 'gatsby-plugin-mailchimp'

import ConditionalLayout from "../components/ConditionalLayout"
import SEO from "../components/SEO"
import Layout from "../components/layout";

class Contact extends React.Component{

  state={
    name:"",
    email:"",
    subject:"",
    message:""
  }
  submitHandler = event => {
    event.preventDefault();
    event.target.className += " was-validated";
    addToMailchimp(this.state.email,
      {
        NAME:this.state.name,
        EMAIL:this.state.email,
        SUBJECT:this.state.subject,
        MESSAGE:this.state.message
      },
      ).then(({msg, result}) => {
        console.log('msg', `${result}: ${msg}`);
        if (result !== 'success') {
          throw msg;
        }
        alert(msg);
      })
      .catch(err => {
        console.log('err', err);
        alert(err);
      });
  };
  changeHandler = event => {
    this.setState({ [event.target.name]: event.target.value });
  };
  render(){
    return(
      <ConditionalLayout>
        <SEO title="Contact" />
        <MDBContainer>
          <MDBRow>
            <MDBCol md="12">
              <form onSubmit={this.submitHandler} className="needs-validation" noValidate>
                <p className="h4 text-center mb-4" style={{paddingTop:'50px'}}>Contact US</p>
                <label htmlFor="defaultFormContactNameEx" type="text" className="grey-text">
                  Your name
                </label>
                <MDBInput
                  type="text"
                  value={this.state.name}
                  name="name"
                  onChange={this.changeHandler}
                  placeholder="Your Name"
                  id="defaultFormContactNameEx"
                  className="form-control"
                  icon="user"
                  required
                />
                <div className="invalid-feedback">
                  Please type your name
                </div>
                <br />
                <label htmlFor="defaultFormContactEmailEx" type="text" className="grey-text">
                  Your email
                </label>
                <MDBInput
                  type="email"
                  value={this.state.email}
                  name="email"
                  onChange={this.changeHandler}
                  placeholder="Your Email"
                  id="defaultFormContactEmailEx"
                  className="form-control"
                  icon="envelope"
                  required
                />
                <div className="invalid-feedback">
                  Please type your email correctly
                </div>
                <br />
                <label
                  htmlFor="defaultFormContactSubjectEx"
                  className="grey-text"
                >
                  Subject
                </label>
                <MDBInput
                  type="text"
                  value={this.state.subject}
                  name="subject"
                  onChange={this.changeHandler}
                  placeholder="Your Subject Message"
                  id="defaultFormContactSubjectEx"
                  className="form-control"
                  icon="pencil-alt"
                  required
                />
                <div className="invalid-feedback">
                  Please type your Subject Message
                </div>
                <br />
                <label
                  htmlFor="defaultFormContactMessageEx"
                  className="grey-text"
                >
                  Your message
                </label>
                <MDBInput
                  type="textarea"
                  value={this.state.message}
                  name="message"
                  onChange={this.changeHandler}
                  placeholder="Your Message"
                  id="defaultFormContactMessageEx"
                  className="form-control"
                  icon="pencil-alt"
                  rows="2"
                  required
                />
                <div className="invalid-feedback">
                  Please type your message
                </div>
                <div className="text-center mt-4">
                  <MDBBtn color="warning" outline type="submit">
                    Send
                    <MDBIcon far icon="paper-plane" className="ml-2" />
                  </MDBBtn>
                </div>
              </form>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </ConditionalLayout>
    )
  }
}

export default Contact
